export const getContactName = (id: string, cusomers: any) => {
  if (cusomers) {
    // TODO fix this is horrible, seaching ALL people in DB
    return cusomers.find((a: any) => {
      if (a.id == id) {
        return a;
      }
    });
  }
};
